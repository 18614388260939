@use "@styles/variables.scss" as variable;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
}

.logo {
  display: flex;
  align-items: center;

  &__img {
    width: 170px;
    height: 26px;
    cursor: pointer;
  }

  &__burger {
    display: none;
  }
}
