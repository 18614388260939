@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.wrapper {
  z-index: 10;
  grid-area: header;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid variable.$color-dark;

  @include media('screen', '<=desktop') {
    position: sticky;
    background-color: variable.$header-bg;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 1248px;
  gap: 32px;
}

.burger_container {
  display: none;

  .logo__burger {
    background: transparent;
    display: flex;
    align-items: center;
  }

  @include media('screen', '<=desktop') {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.navbar_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media('screen', '<=desktop') {
    display: none;
  }
}
