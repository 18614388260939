@use "@styles/variables.scss" as variable;

.cancel_button {
  color: variable.$color-white;
  background-color: transparent;
  border: 1px solid variable.$color-regular-grey;
}

.cancel_title {
  color: variable.$color-black;
  font-weight: 500;
}
