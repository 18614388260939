.select-container {

  position: static !important;

  .custom-select {

    &__group-heading {
      padding: 0 16px !important;
      line-height: 16px !important;
      color: #9D9D9D !important;
      text-transform: capitalize !important;

      &:not(:first-child) {
        padding-top: 8px !important;
        margin-top: 8px !important;
      }
    }

    &__menu {
      top: 76px !important;
      width: 100% !important;

      @media (min-width: 1300px) {
          top: 55px !important;
      }
    }

    &__option {
      padding-left: 10px !important;
      height: 40px !important;
      font-size: 36px !important;
    }

    &__single-value {
      width: 90px !important;
    }
  }
}
