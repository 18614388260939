@use "@styles/variables.scss" as variable;

.button {
  width: 162px;
  height: 54px;
  background-color: variable.$primary-new;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
    background-color: variable.$button-pressed;
  }
}
