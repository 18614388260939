@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.content_wrapper {
  grid-area: wrapper;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 74px 1fr max-content;
  grid-template-areas:
    "header"
    "main"
    "footer";
  position: relative;

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

@media (max-width: 570px) {
  .content_wrapper {
    grid-template-rows: 74px 1fr auto;
  }
}

.back_layer {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 720px;
  overflow: hidden;

  &::before, &::after {
    content: '';
    position: absolute;
    border-radius: 10rem;
    filter: blur(25px);
    user-select: none;
    pointer-events: none;
  }

  &::before {
    right: calc(50% - 746px);
    top: 76px;
    width: 586px;
    height: 586px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(195, 245, 51, 0.40) 0%, rgba(21, 21, 21, 0.00) 100%);
  }
  &::after {
    left: calc(50% - 746px);
    top: 38px;
    width: 515px;
    height: 515px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(195, 245, 51, 0.40) 0%, rgba(21, 21, 21, 0.40) 100%);
  }

  @include media("screen", "<=tablet") {
    &::after {
      display: none;
    }
    &::before {
      width: 515px;
      height: 515px;
      right: -189px;
      bottom: -252px;
    }
  }
}