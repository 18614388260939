@use "@styles/variables.scss" as variable;

.review {
  &_button {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 16px 32px;
    border-radius: 8px;
    border: 2px solid variable.$primary-new;
    background-color: transparent;
  
    &:hover {
      cursor: pointer;
      background-color: transparent;
    }
  
    &:active {
      background-color: variable.$review-dark;
      border-color: variable.$review-dark;
    }
  }

  &_title {
    color: variable.$primary-new;
  }
}
