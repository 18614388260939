@use "@styles/variables.scss" as variable;

.exchange {
  &_button {
    width: 162px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 16px 32px;
    border-radius: 8px;
    background-color: variable.$primary-new;
  
    &:hover {
      background-color: variable.$primary-new-dark;
    }
  
    &:active {
      background-color: variable.$primary-new-light;
    }
  }

  &_title {
    color: variable.$color-black;
    font-weight: 500;
  }
}
