@use "@styles/variables.scss" as variable;

.navbar {
  height: 24px;
  display: flex;
  gap: 40px;

  div {
    cursor: pointer;

    p:hover {
      color: variable.$color-white;
    }
  }

  .isActive {
    color: variable.$color-white;
  }
}
