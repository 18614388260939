@use "@styles/variables.scss" as variable;

.wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 778px;
  height: 120px;
  padding: 24px;
  background: variable.$color-dark-grey;
  border: 1px solid #a7a7cd;
  border-radius: 16px;
  opacity: 0;
  transition: 1s;
  animation: show 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  z-index: 100;

  @media (max-width: 815px) {
    flex-direction: column;
    gap: 32px;
    height: max-content;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  }

  .cookie_text {
    max-width: 494px;

    &__title {
      font-size: 12px;
      line-height: 16px;
      color: variable.$text-grey;
    }

    &__link {
      font-size: 12px;
      line-height: 16px;
      color: variable.$text-grey;
      text-decoration: underline;

      @media (max-width: 815px) {
        margin-top: 10px;
      }
    }
  }

  .cookie_btn {
    width: 116px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: variable.$color-dark;

    @media (max-width: 815px) {
      width: 100%;
    }
  }
}

.hide {
  transition: 1s;
  animation: hide 2s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  pointer-events: none;
  display: none;
}

.hidden {
  display: none !important;
  transition: 1s;
  animation: hide 2s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
