@use "@styles/variables.scss" as variable;

.back_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 0;
  padding: 0;
  background: transparent;
}

.back_title {
  font-size: 16px;
  font-weight: 400;
  color: variable.$primary-new;
}
