@use './variables.scss' as variable;
@import '/styles/media.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body, textarea {
  margin: auto;
  font-family: -apple-system, Noto Sans, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: variable.$color-bg;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background: variable.$color-dark;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 56px;
  font-weight: 700;
  background: linear-gradient(91deg, variable.$primary-new -4.67%, variable.$color-white 108.56%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include media('screen', '<=tablet') {
    font-size: 28px;
    line-height: 36px;
  }
}

h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color:  variable.$color-white;
}

h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: variable.$text-grey;
}

h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: variable.$text-grey;
}

h5 {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: variable.$color-white;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: variable.$color-regular-grey;
}

button {
  border: none;
  outline: none;
}


@include media('screen', '<=tablet') {
  h1 {
    font-size: 28px;
    line-height: 36px;
  }

  h2 {
    font-size: 24px;
    line-height: 33px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
  }

  h4 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }
}

.scroll-fixed {
  overflow: hidden;
}


@import "Select.module";
