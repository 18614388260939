@use "@styles/variables.scss" as variable;

.socials {
  grid-area: social;
  height: 48px;

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
  }

  &:only-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_links {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 22px;
    cursor: pointer;
  }
}
