@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.nav_lang {
  position: relative;
  display: flex;
  color: variable.$color-regular-grey;

  &:hover,
  &.opened {
    color: variable.$color-white;
  }

  &_select {
    color: currentColor;
    outline: none;
    cursor: pointer;
  }

  &_options {
    position: absolute;
    z-index: 1;
    right: 0;
    display: none;
    flex-direction: column;
    width: 44px;
    margin: 0;
    border: white 0.5px solid;
    background-color: variable.$header-bg;

    &__opened {
      display: flex;
    }
  }

  &_option {
    width: 100%;
    text-align: center;
    margin: 0;
    background: none;

    &:hover {
      background: rgba($color: variable.$color-white, $alpha: 0.2);
    }
  }

  &_target {
    display: flex;
    min-width: 20px;
    justify-content: flex-end;
  }

  &_select_burger {
    color: variable.$color-white;
  }
}
