@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.wrapper {
  grid-area: footer;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: variable.$footer-bg;
  border-top: 1px solid variable.$footer-border;
}

.content {
  width: 100%;
  max-width: 1248px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px 16px;

  &__grid {
    @include media("screen", "<=tablet") {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 16px;
      grid-template-areas:
        "logo logo"
        "link social";
    }
  }
}

.divisor {
  width: 100%;
  height: 1px;
  background-color: variable.$footer-border;
}

.logo {
  grid-area: logo;

  &__img {
    width: 123px;
    height: 19px;
    margin-bottom: 16px;
    object-fit: cover;
    cursor: pointer;
  }

  &__title {
    width: 190px;
    font-size: 12px;
    line-height: 14px;
    color: variable.$color-white;
  }

  &__mini_logo {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    .mini_logo {
      width: 18px;
      height: 14px;
      background: url(../../../../public/svg/icons/ua_icon.svg) no-repeat
        center center;
      background-size: cover;
    }

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
    }
  }
}

.links {
  grid-area: link;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  .link_item {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: variable.$color-white;

    &:hover {
      text-decoration: underline;
    }
  }

  &:nth-child(n) {
    cursor: pointer;
  }
}

.rules {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: variable.$color-white;
  cursor: default;
}

@include media("screen", "<=desktop") {
  .content {
    padding: 24px 16px;
  }
}

@include media("screen", "<=tablet") {
  .logo {
    &__title {
      width: 100%;
    }
  }
}
